body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i.icon-5.get-assign-icon {
  background-image: url(../public/assets/images/access-card.svg);
}

.filtered-visitors,
.filtered-visitor {
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
}
.filtered-visitors-status {
  margin-top: 10px;
}

@media screen and (max-width: 795px) {
  .filtered-visitors {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
  }
  .filtered-visitor {
    width: 100%;
  }

  .filter-date-visitors .react-date-picker__inputGroup__input {
    margin-top: 27px;
  }
}

.showvisitors {
  color: blue;
  text-decoration: underline;
}
.showvisitors:hover {
  cursor: pointer;
}

#react-hot-toast {
  z-index: 9999 !important;
}
.analysis-visitors {
  display: flex;
  width: 100%;
  height: 96px;
  /* padding: 0px 24px; */
  justify-content: start;
  gap: 40px;
  margin: 7px 0px 33px 0px;
}
.analysis-visitors.modal-visitors {
  justify-content: center !important;
}
.analysis-visitors > .analysis-visitors-card1 {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  height: 110px !important;
  max-width: 152px !important;
  align-self: stretch;
  border-radius: 20px;
  background: #fbecea;
}
.analysis-visitors > .analysis-visitors-card1 > .card1-data-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.analysis-visitors-card1.visitors-card3 > .card1-data-row > .card1-data-row-1 {
  gap: 55px !important;
}

.analysis-visitors
  > .analysis-visitors-card1
  > .card1-data-row
  > .card1-data-row-2 {
  color: var(--Color-4, #9494ab);
  font-family: "Nunito Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.analysis-visitors
  > .analysis-visitors-card1
  > .card1-data-row
  > .card1-data-row-1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.visitors-header-name {
  color: var(--Color-1, #151518);
  text-align: center;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
}

.analysis-visitors-card1.visitors-card2 {
  background: #f1f9ea !important;
}

.analysis-visitors-card1.visitors-card4 {
  background: #fbfbe7 !important;
}
.analysis-visitors-card1.visitors-card3 {
  background: #eaf6f9 !important;
}
.analysis-visitors-card1.visitors-card5 {
  background: #e3f5ed !important;
}
.analysis-visitors-card1.visitors-card6 {
  background: #f9efe3 !important;
}
.analysis-visitors-card1.visitors-card7 {
  background: #f0f4ce !important;
}
/* background-color: #e3f5ed; */

/* {
  background-color: #f9efe3;
  color: #da7805;} */

div.add-visitor-number {
  color: var(--Color-2, #8181a6);
  font-family: "Nunito Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.customvisitor-modal.modal-dialog {
  min-width: calc(100% - 100px);
  /* min-height: calc(100% - 50px); */
}

/* Make the modal body scrollable */
.modal-scrollable-body {
  min-width: 89vw;
  /* max-height: 70vh;  */
  overflow-y: auto;
  padding: 1rem;
}

.wrapper.visitor-wrapper {
  padding-left: 0px !important;
}

.status.pending-tag {
  background-color: #e0e0e0 !important;
}

.my-event-visitors .modal-content {
  height: 90vh;
}
.customvisitor-modal .table-header-2 {
  margin-top: 30px;
}

.add-visitor-number {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.visitor-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
}

.visitor-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* max-width: 400px;  */
  text-align: left;
  font-size: 1rem;
  color: #333;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.visitor-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.visitor-info {
  flex: 1;
}

.visitor-info p {
  margin: 4px 0;
}

.delete-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.delete-btn:hover {
  background-color: #f8d7da; /* Light red hover effect */
}

.delete-btn img {
  width: 20px;
  height: 20px;
}

/* .visitor-card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
} */

.addVisitorBtn {
  display: flex;
  justify-content: space-between;
}
.delete-btn {
  color: red;
}

td.dataNotFound {
  /* display: flex;
  justify-content: center; */
  text-align: center;
}

input.form-control.newDateInput {
  /* width: 230px !important; */
}

.form-group.dateForm {
  display: flex;
  flex-direction: column;
}

.form-group.buildingUserCustom .css-b62m3t-container {
  z-index: unset !important;
}

.description-wrapper {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
  min-width: 250px !important;
  padding: 8px !important;
}
.description-detail {
  min-width: 250px !important;
}

.building-b {
  border-top: 1px solid #cdcdda;
}

.card-details-iaq {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  height: 100%;
}

.iaq-center {
  font-family: Nunito Sans;
  font-size: 13.66px;
  font-weight: 600;
  line-height: 16.82px;
  letter-spacing: -0.5044897794723511px;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #8181a6 !important;
}

.temp-card {
  font-family: Nunito Sans;
  width: calc(50% - 10px);
  /* height: 156px; */
  border-radius: 6px 0 0 0;
  border: 1px solid #eeeef4;
  padding: 24px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.averages-card {
  display: flex;
  gap: 10px;
  text-align: right;
}

.temp-section {
  display: flex;
  justify-content: space-between;
  height: 112px;
  /* width: 69px; */
  height: 52px;
  flex-direction: column;
  font-size: 18px;
}

.temp-icon {
  font-size: 24px;
  color: #8181a6;
}

.temp-value {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #2c2c44;
  letter-spacing: -0.48px;
}

.averages {
  height: 40px;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.average-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 11px;
  color: #8181a6;
  /* text-align: end; */
}

.average-value {
  font-weight: bold;
  color: #2b224e;
}

.down {
  color: #ff4d4f;
  font-size: 12px;
  margin-left: 4px;
}

.up {
  color: #52c41a;
  font-size: 12px;
  margin-left: 4px;
}

.building-rating-head {
  background: white;
  border-radius: 6px;
  padding: 24px;
}

.card-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #151518;
  border-bottom: 1px solid #cdcdda;
  padding-bottom: 16px;
  margin-bottom: 34px;
}
.card-title-top-3 {
  border-bottom: unset;
}
.top-three-card {
  background: white;
  height: 100%;
  border-radius: 6px;
  padding: 24px;
}

@media screen and (max-width: 1161px) {
  .card-details-iaq {
    margin-top: 20px !important;
  }
  .top-three-card {
    margin-top: 40px !important;
    height: auto;
  }
}

@media screen and (max-width: 623px) {
  .temp-card {
    min-width: 100% !important;
  }
}

.power-card-title {
  color: #151518;
  border-bottom: 1px solid #cdcdda;
  padding-bottom: 16px;
}
.carbonfb-title {
  padding-bottom: 16px;
}
.power-usage {
  background-color: white;
}
.power-usage-chart {
  display: flex;
}
.power-usage-graph,
.power-usage-rating {
  width: 50%;
}
.power-usage-graph {
  height: 80%;
}

.white-bg.power-usage-card {
  background-color: unset;
  background: unset;
  padding: unset;
}
.power-usage-card {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}
.power-usage-card-container {
  width: calc(100% / 2 - 10px);
  height: 161px;
  gap: 0px;
  border-radius: 6px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
}
.power-p1 {
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2c44;
}
.power-p1 span {
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.power-p2 {
  font-family: Nunito Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8181a6;
}

.power-usage-card-container > img {
  width: 24px;
  height: 24px;
}

.power-usage-container {
  /* min-width: 281px; */
  height: 336px;

  border-radius: 6px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  background: white;
  padding: 24px;
  font-family: Nunito Sans;
}
.power-p3 {
  font-size: 9px;
  margin-bottom: 30px;
}

.top-three-container {
  /* min-width: 281px; */
  height: 336px;
  border-radius: 6px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  padding: 24px;
  font-family: Nunito Sans;
}
.meter-usage-details_power {
  display: flex;
  flex-direction: column;
  height: 86px;
}

.power-usage-container h1,
.top-three-container h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 16px;
}
.top-meter-usage {
  display: flex;
  gap: 12px;
  height: 50px;
}

.meter-usage-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.meter-usage-p1 {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151518;
}
.meter-usage-p2 {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8181a6;
}

.energy-usage-container {
  min-width: 281px;
  height: 201px;
  top: 134px;
  left: 1270px;
  gap: 0px;
  border-radius: 6px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  background: white;
  padding: 24px;
  font-family: Nunito Sans;
}

.energy-usage-container h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 16px;
}

.scale-container {
  margin-top: 10px;
  width: 70%;
}

.scale-bar {
  position: relative;
  display: flex;
  height: 10px;
  width: 100%;
  background-color: #e6e6e6; /* Grey for the entire scale */
  /* border-radius: 5px; */
  overflow: hidden;
  margin-bottom: 5px;
}

.orange-bar {
  position: absolute;
  height: 100%;
  background-color: #ffa53b;
  /* border-radius: 5px 0 0 5px; */
}
.green-bar {
  position: absolute;
  height: 100%;
  background-color: #17cb75;
  /* border-radius: 5px 0 0 5px; */
}

.green-text {
  color: #17cb75;
}

.scale-markers {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #9494ab;
}

.energy-usage-details {
  display: flex;
  align-items: end;
}

.scale-data {
  margin-left: 45px;
}
.power-perc {
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 4px;
}
.power-weekly {
  margin-top: 11px;
}
.battery-image {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 16px;
}

.custom-table.power-meter-table {
  margin-top: 24px;
}

.power-meter-title {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.scale-markers span {
  position: relative;
}
.scale-markers span:not(:first-child, :last-child)::before {
  content: "";
  position: absolute;
  left: 10px;
  width: 1px;
  height: 10px;
  top: -15px;
  background: #fff;
}

.power-meter-p1 {
  font-family: Nunito Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2c44;
}

.top-three-sensors {
  background: unset !important;
  margin-left: -9px !important;
}

@media screen and (max-width: 1185px) {
  .power-usage-card {
    margin-top: 20px;
  }
  .power-usage-container {
    margin-bottom: 20px;
  }
  .top-three-sensors {
    margin-top: 44px !important;
    margin-left: 0px !important;
  }
}
.power-meter-p1 span {
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #2c2c44;
}

.top-three-sensors > div {
  background-color: #fff;
  height: 100% !important;
  padding: 0px;
}
.occupancy-in-out-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8181a6;
  margin-bottom: 8px;
}

.scale-bar-occupancy {
  display: flex;
  align-items: center;
  height: 10px;
  width: 100%;
  margin-top: 4px;
  gap: 8px;
}

.green-bar-occupancy {
  background-color: #17cb75;
  height: 100%;
}

.grey-bar-occupancy {
  background-color: #9494ab !important;
  height: 100%;
}

.grey-bar {
  width: 100%;
  background-color: transparent;
  height: 10px;
  /* border: 1px solid #ccc;  */
  position: relative;
  overflow: hidden;
}

.green-text {
  color: #17cb75;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.grey-text {
  color: #9494ab;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.text-occupancy {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.48px;
  min-width: 25px;
  height: 18px;
  display: flex;
  justify-content: start;
}

.mt-2 {
  margin-top: 8px;
}

.average-hr-peak-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  background-color: transparent;
}
.average-hr-peak-card h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 16px;
}
.average-hr-peak-card {
  min-width: 274px;
  height: 156px;
  border-radius: 6px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  background: #ffffff;
  padding: 24px;
}

.occupants-peak-time {
  font-family: Nunito Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.47999998927116394px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8181a6;
}
.occupants-peak-time span {
  color: #17cb75;
}

.entry-traffic-container {
  min-width: 281px;
}

.heap-map-container {
  width: 100%;
  /* height: 336px; */
  border-radius: 6px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  background: white;
  padding: 24px;
  font-family: Nunito Sans;
}
.heap-map-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  text-decoration-skip-ink: none;
  margin-bottom: 16px;
  color: #151518;
  border-bottom: 1px solid #cdcdda;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.heap-map-btn {
  width: 57px;
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid #e1e1ed;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heap-map-btn-container {
  display: flex;
  gap: 12px;
}

.heap-map-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 43.71px);
  gap: 8px;
  align-items: center;
  justify-content: start;
  overflow-x: auto;
  padding: 10px;
}

.heap-map-cell {
  width: 43.71px;
  height: 10px;
  text-align: center;
  border-radius: 2px;
  color: white;
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.heap-text {
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #9494ab;
}

.text-up {
  color: green;
}

.text-down {
  color: red;
}

.text-neutral {
  color: inherit;
}

.parking-trend-chart path.highcharts-point {
  display: none;
}

/* .custom-power-graph {
  position: relative;
  width: 270px !important; 
  height: 198px; 
  overflow: hidden;
  padding: 2px !important;
}

.custom-power-graph .highcharts-container {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; 
}

.custom-power-graph .highcharts-container:nth-child(2) {
  z-index: 2; 
}
.custom-power-graph div:nth-child(2) .highcharts-container {
  left: 11px !important;
  top:21px !important;
}


.custom-power-graph rect.highcharts-background {
  fill: none;
} */

.alarmData-name {
  /* max-width: 30px;  */
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.status-car-table {
  color: white;
  font-size: 12px;
  border-radius: 24px;
  padding: 4px 11px;
  font-weight: 600;
}

/* .textRed{
  color: red !important;
} */

.iaq-value-time {
  margin-top: 12px;
}
.iaq-value-time > p {
  font-size: 12px;
  /* border-bottom: 1px solid #dedef0; */
}

.total-number-power {
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.03em;
  color: #18182b;
  margin-bottom: 9px;
}
.power-table-margin {
  margin-bottom: 16px;
}


.label-title-power {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.01em;
  color: #8181a7;
  text-transform: uppercase;
}

/* .equipmentBody{
  padding-bottom: 30px;
}

.solarIrradiance{
  height: 361px;
} */

.toBeCheckOut {
  text-align: center;
}

/* General styles for the report table */
.report-table {
  width: 100%;
  background: #fff;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  padding-bottom: 20px;
  text-align: left;
  max-height: 500px !important;
}

.report-inner-table {
  width: 100%;
  padding: 20px 10px 40px;
  /* max-width: 660px; */
  min-width: 1599px !important;
  max-width: 1599px !important;
  margin: 50px auto 30px;
  border-collapse: collapse;
}

.logo-cell {
  text-align: center;
}

.logo-image {
  margin: 60px auto 30px;
  max-width: 210px;
  width: 100%;
}

.report-container {
  width: 100%;
  margin: 0 auto;
  padding: 0px 0px;
  border-radius: 10px;
  border: 1px solid;
}

.report-header {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  margin: 0px;
  font-weight: 400;
  background-color: #323030;
  padding: 20px 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.report-period-label {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  line-height: 1.67;
  color: #000;
  margin-top: 10px;
  padding: 10px 20px 0px;
  margin-bottom: 0;
}

.report-period-date {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 19.5px;
  line-height: 1.67;
  color: #000;
  padding: 0px 20px 10px;
  margin-bottom: 0;
}

.report-content-wrapper {
  width: 100%;
}
.report-content-table tbody{
  /* background: #fdfdfdf4; */
  background-color: unset;
  background: unset;
  background-color: #FBFBFB;
}
.report-content-table>tbody>tr>td{
  padding: 10px 30px;
}
.report-content-table>tbody>tr{
  background-color: unset;
  background: unset;
  background-color:#F6F6F6;
  /* border-bottom: 1px solid #000000; */
    width: 1589px !important;
}
.report-content-table {
  width: 90%;
  padding: 20px 10px 0px;
  margin: 50px auto 20px;
  border-radius: 10px;
  border-collapse: collapse;
}

.content-header {
  text-align: left;
  font-size: 25px;
  font-weight: 500;
  background-color: #323030;
  color: #fff;
  padding: 20px 25px;
  border-top-left-radius: 10px;
}

.content-id {
  text-align: right;
  font-weight: 400;
  color: #fff;
  background-color: #323030;
  padding: 20px 25px;
  border-top-right-radius: 10px;
}
.report-text-right{
  text-align: right;
  padding-right: 50px;
}

.chart-container {
  margin-top: 30px;
  text-align: center;
}

.chart-area {
  margin-top: 20px;
}


#reportContent {
  width: 100%; /* Full page width */
  overflow: visible; /* Avoid clipping content */
  box-sizing: border-box; /* Include padding in width/height calculations */
  margin: 0 auto; /* Center the content horizontally */
  padding: 10px; /* Add padding around the content */
  font-size: 21px;
  
}

.report-overview {
  width: 100%;
  margin-bottom: 20px; 
  font-size: 21px;
}

.report-overview-header {
  background-color: #323030;
  width: 100%;
  height: 60px;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.report-header-red{
  display: flex;
  gap: 6px;
}
.report-overview-header .red-header {
  width: 7px;
  height: 30px;
  border-radius: 10px;
  background-color: red;
}

.report-overview-header {
  font-weight: 900;
}

.report-date{
  font-size: 19px;
}

/* Section containing graphs and details */
.report-chart-details {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping for smaller screens or paper */
  justify-content: space-between;
  gap: 20px;
  width: 100%; /* Ensure full width */
  margin-top: 20px; /* Space at the top */
  padding: 0 10px; /* Padding for the content */
}

/* Each individual graph + details container */
.report-graph-details {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%; /* Full width for content */
  margin-bottom: 20px;
}

/* Power chart section */
.report-chart-power {
  width: 78%; 
  background-color: white !important;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); 
}

/* Power details section */
.report-chart-power-details {
  width: 20%; /* Adjusted width for details */
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: adds shadow */
}

/* Title of each section */
.report-name {
  font-size: 21px; /* Adjust font size for print */
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px; /* Add spacing below the name */
}

/* Styling for power details parts */
.report-chart-power-details-part {
  margin-bottom: 12px;
  border-bottom: 1px solid #dedef0; /* Subtle divider between details */
}

/* Adjusting total power, average power, etc. */

.report-total-number-power {
  font-size: 23px; /* Adjusted font size */
  color: #18182b;
}

.report-average-co2,.report-label-title-power {
  text-transform: uppercase;
  font-size:18px !important;
}
.report-label-title-power {
  color: #8181a7;
}

.report-total-number-power {
  font-size: 21px;
  font-weight: bold;
  color: #18182b;
  line-height: 1.4;
}

.report-average-co2 {
  color: teal; /* For N.A values */
}

/* Handling page breaks between sections */
.page-break {
  page-break-before: always;
  /* page-break-after: always; */
  page-break-inside: avoid;
}

/* Time label for maximum and minimum power */
.time-power {
  font-size: 12px;
  color: #8181a7;
}

/* Green color for minimum power values */
.text-green {
  color: green;
}


.fullWidthTable {
  width: 100%; /* Full width */
  border-collapse: collapse; /* Remove default spacing between borders */
}

.fullWidthTable th, .fullWidthTable td {
  padding: 10px;
  text-align: center;
}

.fullWidthTable tr {
  border-bottom: 1px solid #ddd; /* Row bottom border */
}

.fullWidthTable th {
  background-color: #f4f4f4; /* Light background for header */
  text-align: center;
}

.report-common-show{
  display: flex;
  align-items: center;
  gap: 15px;
  /* max-width: 360px; */
}
.report-wrap-text{
  white-space: pre-wrap;       
  word-wrap: break-word;       
  font-family: monospace;
}

.report--chart-row{
  max-width: 370px;
}

.css-1n6sfyn-MenuList,.react-custom-select__menu-list,.css-qr46ko{
  max-height: unset !important;
}

.hidden-for-pdf {
   position: absolute;
  top: -9999px;
  left: -9999px;
  /* visibility: hidden;  */
}

/* #reportContent{
  min-width: 1800px !important;
  max-width: 1800px !important;
  background-color: white !important;

}
.report-table{
  margin-bottom: 1753px;
}
#page1{
  margin-top: 150px;
  min-height: 2350px !important;
  max-height: 2350px !important;

}
#page2{
  margin-top: 30px;
  min-height: 2000px !important;
  max-height: 2000px !important;
} */

.alarm-blk-custom{
  max-height: 286px !important;
  overflow: auto;
}



.common-h{
  height: 500px !important;
}
.custom-b{
  margin-bottom: 30px !important;
}

.main-ticket-category {
  height: 350px !important;
}

@media (max-width:1200px){
  .main-ticket-category {
    height: auto !important;
  }
}